
import { defineComponent, computed, PropType } from '@vue/composition-api';
import { StoryData, StoryblokComponent } from 'storyblok-js-client';

interface GlobalContent extends StoryblokComponent<string> {
  global: [
    {
      component: string;
      [key: string]: any;
    }
  ];
}

export default defineComponent({
  name: 'GlobalReference',
  props: {
    reference: {
      type: Object as PropType<StoryData<GlobalContent>>,
      required: true,
    },
  },
  setup(props) {
    const component = computed(() => props?.reference?.content?.global[0] ?? {});

    return {
      component,
    };
  },
});
